import * as React from "react"
import Layout from '../components/Layout/Layout';
import HomePage from './home';

const IndexPage = () => {
  return (
    <Layout>
        <HomePage />
    </Layout>
  )
}

export default IndexPage
