import React from "react";
import styles from "./home.module.scss";
import LinesImg from "../assets/images/bg_lines.svg";
import PlusIcon from "../assets/icons/plus.svg";
import DollarIcon from "../assets/icons/dollar.svg";
import EqualIcon from "../assets/icons/equal.svg";
import Button from "../components/UI/Button/Button";
import CirclesShapes from "../components/UI/CirclesShapes/CirclesShapes";
import Title from "../components/UI/Title/Title";
import clsx from "clsx";
import TryNowButton from "../components/TryNowButton/TryNowButton";
import ContactSalesButton from "../components/ContactSalesButton/ContactSalesButton";
import SEO from "../components/SEO";
import Img from "gatsby-image";
import { useSiteImages } from "../hooks/useAllImages";

const imagesForImport = [
  "heroes",
  "phone_and_avatars",
  "phone_and_avatars_mob",
  "circles",
  "double_circle",
  "social_1",
  "social_2",
  "hand",
];
const HomePage = () => {
  const images = useSiteImages(imagesForImport);

  const SocialBox = ({ type, icon, title }) => (
    <div
      className={clsx(
        styles.socialBox,
        type === "blue" ? styles.socialBlueBox : styles.socialBorderBox
      )}
    >
      {title}
      <div className={styles.socialIcon}>{icon}</div>
    </div>
  );

  return (
    <div className={styles.page}>
      <SEO title="Home" />
      <section className={styles.head}>
        <Title title="People power" variant="h1" weight="extra-bold" />
        <Title
          title="your business"
          variant="h2"
          size="large"
          weight="lighter"
          classProp={styles.subTitle}
        />
        <Title
          title="TappShare empowers your people to power your bottom-line."
          variant="h5"
          classProp={styles.slogan}
        />
        <TryNowButton shadow="primary-dark" />
        <div className={styles.mainImageBox}>
          <Img fluid={images.heroes} className={styles.img} />
        </div>
        <LinesImg className={styles.lines} />
        <CirclesShapes color="primary" classProp={styles.circlesShapes} />
      </section>

      <section className={styles.marketing}>
        <Title
          title="Kickstart your inbound"
          color="white"
          variant="h3"
          size="large"
          weight="extra-bold"
          classProp={styles.title}
        />
        <Title
          title="and outbound marketing"
          color="white"
          variant="h3"
          weight="lighter"
          classProp={styles.subTitle}
        />
        <div className={styles.mainImgContainer}>
          <div className={styles.mainImg}>
            <Img fluid={images.phone_and_avatars} className={styles.img} />
          </div>
          <div className={styles.mainMobImg}>
            <Img fluid={images.phone_and_avatars_mob} className={styles.img} />
          </div>
          <div className={styles.circlesBox}>
            <Img fluid={images.circles} className={styles.circles} />
          </div>
        </div>

        <div className={styles.blueBox}>
          <div className={styles.content}>
            <Title
              title="TappShare transforms your team"
              color="white"
              variant="h3"
            />
            <Title
              title="into your most effective top-of-funnel marketing channel by making it easy for them to share your content across their personal networks leveraging private messaging channels."
              variant="text"
              color="white"
              weight="lighter"
              classProp={styles.description}
            />

            <Button
              title="Learn more"
              background="cyan"
              textColor="primary-dark"
              size="small"
              classProp={styles.learnMore}
            />
          </div>
          <div className={styles.background} />
          <div className={styles.circles}>
            <Img fluid={images.double_circle} className={styles.img} />
          </div>
        </div>

        <div className={styles.bgCircle}>
          <div />
        </div>

        <div className={styles.social}>
          <Title
            title="Get your content into"
            color="white"
            variant="h3"
            size="large"
            weight="extra-bold"
            classProp={styles.title}
          />
          <Title
            title="private social messaging channels "
            color="white"
            weight="lighter"
            variant="h3"
            classProp={styles.subTitle}
          />

          <div className={styles.images}>
            <div className={styles.leftImg}>
              <Img fluid={images.social_1} className={styles.img} />
            </div>
            <div className={styles.rightImg}>
              <Img fluid={images.social_2} className={styles.img} />
            </div>
            <div className={styles.handImg}>
              <Img fluid={images.hand} className={styles.img} />
            </div>
          </div>

          <div className={styles.info}>
            <SocialBox icon={<PlusIcon />} title="Engaged teams" />
            <SocialBox
              icon={<EqualIcon />}
              title="Easy, Shareable, Mobile-Ready Content"
            />
            <SocialBox
              icon={<DollarIcon />}
              type="blue"
              title="Highly Activated Audiences"
            />
          </div>
        </div>
      </section>

      <section className={styles.contact}>
        <TryNowButton shadow="primary-dark" />
        <ContactSalesButton
          classProp={styles.salesBtn}
          textColor="primary"
          size="large"
        />
      </section>
    </div>
  );
};

export default HomePage;
